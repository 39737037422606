import { cloneElement } from "react";

import { Link } from "~/components/Link";
import { cn } from "~/utils/classnames";

import "./ButtonOld.css";
import type { ButtonOldProps } from "./ButtonOld.types";

/**
 * Composant d'intéraction utilisateur pour naviguer ou déclencher une action.
 */
export const ButtonOld = ({
  as = "button",
  type = "button",
  variant = "filled",
  color = "primary",
  size = "md",
  uppercase = true,
  label,
  IconComponent,
  iconPosition = "left",
  hoverEffect,
  className,
  iconClassName,
  onClick,
  iconOnly,
  subLabel,
  target,
  buttonRef,
  ...props
}: ButtonOldProps) => {
  const Icon = IconComponent
    ? cloneElement(IconComponent, {
        className: cn(
          "ButtonOld-icon text-current fill-current stroke-current w-4 h-4 svgIcon",
          iconClassName
        ),
      })
    : null;

  const buttonChildren = (
    <>
      {Icon && iconPosition === "left" ? (
        <span className="ButtonOld-iconWrapper">{Icon}</span>
      ) : null}
      {label ? (
        <span className={cn("ButtonOld-content", iconOnly && "sr-only")}>
          <span className={cn("ButtonOld-label")}>{label}</span>
          {subLabel ? <span className="ButtonOld-subLabel">{subLabel}</span> : null}
        </span>
      ) : null}
      {Icon && iconPosition === "right" ? (
        <span className="ButtonOld-iconWrapper">{Icon}</span>
      ) : null}
    </>
  );

  if (as === "a") {
    return (
      <Link
        className={cn(
          "ButtonOld",
          `ButtonOld--variant-${variant}--color-${color}`,
          `ButtonOld--size-${size}`,
          `ButtonOld--iconPosition-${iconPosition}`,
          `ButtonOld--hoverEffect-${hoverEffect}`,
          uppercase && "ButtonOld--text-uppercase",
          props.disabled ? "ButtonOld--disabled" : "",
          className
        )}
        to={props.href || ""}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          }
          if (onClick) {
            onClick(e);
          }
        }}
        target={target}
        {...props}
      >
        {buttonChildren}
      </Link>
    );
  }

  return (
    <button
      className={cn(
        "ButtonOld",
        `ButtonOld--variant-${variant}--color-${color}`,
        `ButtonOld--size-${size}`,
        `ButtonOld--iconPosition-${iconPosition}`,
        `ButtonOld--hoverEffect-${hoverEffect}`,
        uppercase && "ButtonOld--text-uppercase",
        className
      )}
      onClick={onClick}
      type={type}
      {...props}
      ref={buttonRef}
    >
      {buttonChildren}
    </button>
  );
};
